import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import isEmail from 'validator/lib/isEmail'

import { createFreeSignup } from '../../../../store/signup/actions'
import SignupUserInfoScreen from '../../../../screens/Signup/Free/UserInfo'

type Props = {
  user?: SignupUserInfo
}

function SignupUserInfoScreenContainer(props: Props & RouteComponentProps) {
  const dispatch = useDispatch()
  const { user, history, promoCode } = props
  const [isLoading, setIsLoading] = useState(false)
  const [isCaptchaSuccessful, setIsCaptchaSuccessful] = useState(false)

  function handleCaptcha() {
    setIsCaptchaSuccessful(true)
  }

  async function handleSubmit(data: SignupUserInfo) {
    setIsLoading(true)

    try {
      if (!data.agree) {
        throw new Error(
          'Please verify you understand that Pet Notify is only available in the US and Canada',
        )
      }
      if (!isCaptchaSuccessful) {
        throw new Error('Captcha is required')
      }
      if (!data.firstName) {
        throw new Error('First name required')
      }
      if (!data.lastName || data.lastName === '') {
        throw new Error('Last name required')
      }
      if (!data.email || data.email === '') {
        throw new Error('Email is required')
      }
      if (!isEmail(data.email)) {
        throw new Error('Email must be a valid email address')
      }
      if (data.email !== data.confirmEmail) {
        throw new Error('Your emails do not match')
      }

      if (!data.city || data.email === '') {
        throw new Error('City is required')
      }

      if (!data.zip || data.zip === '') {
        throw new Error('Zip is required')
      }

      setIsLoading(false)
      await dispatch(createFreeSignup({ ...user, ...data, promoCode }))
      history.push('/signup/success')
    } catch (e) {
      setIsLoading(false)
      throw e
    }
  }

  return (
    <SignupUserInfoScreen
      onSubmit={handleSubmit}
      onCaptcha={handleCaptcha}
      isLoading={isLoading}
      initialValues={user}
    />
  )
}

type ReduxState = {
  signup: {
    user?: UserInfo
  }
}

export default connect((state: State) => ({
  user: state.signup.user,
  promoCode: state.promoCode && state.promoCode.code,
}))(SignupUserInfoScreenContainer)
