import React from 'react'
import { Route } from 'react-router-dom'

import Loader from 'components/Loader'
import Text from 'components/Text'
import Modal from 'sharedComponents/Modal'
import Button from 'sharedComponents/Button'
import LocationDetailsForm from 'UserApp/containers/Alarm/LocationDetailsForm'
import HeaderOne from 'components/Headers/HeaderOne'
import DefaultAlarmScreen from './Default'
import GoingOffAlarmScreen from './GoingOff'
import ReadyAlarmScreen from './Ready'
import style from './style.module.scss'

function AlarmScreen(props) {
  const { onTriggerSOS, isLoading } = props

  const Screen = (() => {
    const { isGoingOff, hasAlarm } = props

    if (isGoingOff) {
      return GoingOffAlarmScreen
    }

    if (hasAlarm) {
      return ReadyAlarmScreen
    }

    return DefaultAlarmScreen
  })()

  function handleClose() {
    props.history.push('/alarm')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Screen {...props} />

      <Route
        path='/alarm/daily-check-in-alarms'
        render={() => (
          <Modal showCloseButton={false}>
            <>
              <HeaderOne className={style.modalHeader}>
                Daily check-in alarms
              </HeaderOne>
              <Text className={style.modalText}>
                Set your daily check-in alarm, and we’ll confirm that you and
                your pets are safe. If your alarm goes off, and you do not
                respond, we will immediately notify your emergency contacts that
                you and your pets need help.
              </Text>
              <Button
                variant='hollow'
                onClick={handleClose}
                className={style.modalButton}
              >
                Ok, got it
              </Button>
            </>
          </Modal>
        )}
      />

      <Route
        path='/alarm/location-alarms'
        render={() => (
          <Modal showCloseButton={false}>
            <>
              <HeaderOne className={style.modalHeader}>
                Location alarms
              </HeaderOne>
              <Text className={style.modalText}>
                Set your automatic location based alarm from your mobile device
                and choose the maximum time you would allow your pets to be
                without care. Your alarm will automatically turn on when you
                leave home, and off when you return.
              </Text>
              <Text className={style.modalText}>
                If your alarm goes off and you do not respond, we will notify
                your emergency contacts with your last known location for the
                immediate care of your and your pets.
              </Text>
              <Button
                variant='hollow'
                onClick={handleClose}
                className={style.modalButton}
              >
                Ok, got it
              </Button>
            </>
          </Modal>
        )}
      />
      <Route
        path='/alarm/location-details'
        render={() => (
          <Modal onClose={handleClose}>
            <>
              <HeaderOne className={style.modalHeader}>
                Where are you going?
              </HeaderOne>
              <Text className={style.modalText}>
                We’ll share this with your emergency contacts if your alarm goes
                off and you don’t respond.
              </Text>
              <LocationDetailsForm onClose={handleClose} />
            </>
          </Modal>
        )}
      />

      <Route
        path='/alarm/sos'
        render={() => (
          <Modal onClose={handleClose}>
            <>
              <HeaderOne className={style.modalHeader}>Confirm SOS</HeaderOne>
              <Text className={style.modalText}>
                Activating SOS will message your emergency contacts to inform
                them you and your pets need immediate help.
              </Text>
              <Button
                onClick={onTriggerSOS}
                className={style.modalConfirmButton}
              >
                Confirm SOS
              </Button>
              <Button onClick={handleClose} variant='hollow'>
                Cancel
              </Button>
            </>
          </Modal>
        )}
      />
    </>
  )
}

export default AlarmScreen
