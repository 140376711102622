import React from 'react'
import PropTypes from 'prop-types'

import Row, * as RowElement from '../Row'

import style from './style.module.scss'

VaultItemTechDevices.propTypes = {
  name: PropTypes.string,
}

function VaultItemTechDevices(props) {
  const { techDevices } = props

  return techDevices.map(
    ({ type, brand, url, username, password, notes }, index) => (
      <div className={style.wrapper} key={`tech-device-${index}`}>
        <Row>
          <RowElement.Title>Type</RowElement.Title>
          <RowElement.Value>{type}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>Brand</RowElement.Title>
          <RowElement.Value>{brand}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>URL</RowElement.Title>
          <RowElement.Value>{url}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>Username</RowElement.Title>
          <RowElement.Value>{username}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>Password</RowElement.Title>
          <RowElement.Value>{password}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>Notes</RowElement.Title>
          <RowElement.Value>{notes}</RowElement.Value>
        </Row>
      </div>
    ),
  )
}

export default VaultItemTechDevices
