import React, { useState } from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'

import Layout from 'sharedComponents/Layout/Split'
import HeaderOne from 'sharedComponents/HeaderOne'
import Loader from 'sharedComponents/Loader'
import Text from 'sharedComponents/Text'
import SetPasswordForm from 'UserApp/components/SetPasswordForm'
import Link from 'sharedComponents/Link'
import { setPassword } from 'UserApp/store/signup/actions'
import MobileHeaderImage from '../MobileHeaderImage'
import mixPanel, { EVENTS } from 'services/mix-panel'
import style from './style.module.scss'

function SignupSuccessScreen(props: RouteComponentProps) {
  const [isLoading, setIsLoading] = useState()
  const history = useHistory()
  const location = useLocation()
  const { hasIntellitag } = queryString.parse(location.search)
  const dispatch = useDispatch()
  const token = useSelector<ReduxState, string | undefined>(
    (state) => state.signup.token,
  )

  async function handleSetPassword(data: SignupPassword) {
    await dispatch(setPassword({ ...data, token }))

    mixPanel.track(EVENTS.SET_PASSWORD_CLICK)

    history.push('/')
  }

  async function handleSetPasswordLater() {
    setIsLoading(true)
    await dispatch(setPassword({ token, later: true }))
    history.push('/')
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Layout>
        <MobileHeaderImage step={5} />
        <HeaderOne className={style.title}>
          Thank you for choosing Pet Notify!
        </HeaderOne>
        {hasIntellitag && (
          <Text className={style.subTitle}>
            Your Intellitag will arrive in 5-7 business days.
          </Text>
        )}

        <Text className={style.subTitleText}>
          Set a password and continue to create your pet’s digital profile.
        </Text>

        <SetPasswordForm className={style.form} onSubmit={handleSetPassword} />

        <Link onClick={handleSetPasswordLater}>
          Skip and we’ll send you an email to set your password later
        </Link>
      </Layout>
      <img
        src='https://pubads.g.doubleclick.net/activity;xsp=4691457;ord=[session id]?'
        width='1'
        height='1'
        border='0'
      />
    </>
  )
}

export default SignupSuccessScreen
