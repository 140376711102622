import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import api from 'services/pet-notify-api'
import HeaderOne from 'sharedComponents/HeaderOne'
import Text from 'sharedComponents/Text'
import Form from 'sharedComponents/Form'
import Label from 'sharedComponents/Label'
import Input from 'sharedComponents/Input'
import Button from 'sharedComponents/Button'
import Loader from 'sharedComponents/Loader'
import Error from 'sharedComponents/Error'
import Link from 'sharedComponents/Link'
import style from './style.module.scss'
import { useHistory } from 'react-router'
import TextArea from 'sharedComponents/TextArea'
import ActivateIntellitagChoosePets from '../ChoosePets'
import useUser from 'UserApp/hooks/useUser'
import UpgradeToPremiumActivateTag from 'UserApp/components/UpgradeToPremiumActivateTag'

type FormValues = {
  intellitagInstructions: string
}

type Props = {
  onSaveIntellitagInsructions: (intellitagInstructions: string) => void,
  order: any
}

function ActivateIntellitagLostPetInstructions({
  onSaveIntellitagInsructions,
  order
}: Props) {

  const [orderInfo, setOrderInfo] = useState()
  const history = useHistory()
  const user = useUser()
  const [intellitagInstructions, setIntellitagInstructions] = useState<string>()

  // async function handleSubmit({ intellitagInstructions }: FormValues) {
  //   onSaveIntellitagInsructions(intellitagInstructions)

  // }

  function handleClose() {
    history.push('/pets/activate-intellitag/activation-code')
  }

  async function onSubmit({ intellitagInstructions }: FormValues) {
    onSaveIntellitagInsructions(intellitagInstructions)
    setIntellitagInstructions(intellitagInstructions)
    if (order)
    {
      setOrderInfo(order)
    }
  }
  console.log(`Lost pet instruction component : `, order)
  console.log(`User verification ::`, user.user.hasSubscription)
  

  return (
    (user.user.hasSubscription===true) ?
    <>
      <HeaderOne>Lost pet instructions</HeaderOne>

      <Text className={style.text}>
        Provide detailed contact and care instructions about your pet that we
        can send to your pet’s rescuer they become lost. The more information
        you can provide the better to ensure you are quickly reunited with your
        lost pet.
      </Text>

      <Form onSubmit={onSubmit}>
        {({ onChange, values }) => (
          <>
            <TextArea
              id='intellitagInstructions'
              value={values.intellitagInstructions}
              onValueChange={onChange}
              name='intellitagInstructions'
              placeholder='Eg. Sally is allergic to nuts, and she responds very well to her name.'
              rows={5}
              className={style.textArea}
            />
            <Button type='submit' className={style.button}>
              Save instructions
            </Button>
          </>
        )}
      </Form>
      </>
      : <UpgradeToPremiumActivateTag onClose={handleClose} order={order}/>
  )
}

export default ActivateIntellitagLostPetInstructions
