import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import isEmail from 'validator/lib/isEmail'
import queryString from 'query-string'

import api from 'services/pet-notify-api'
import { createTempUser } from '../../../../store/signup/actions'
import SignupUserInfoScreen from '../../../../screens/Signup/Premium/UserInfo'
import useCaptureSignupParams from '../../../../hooks/useCaptureSignupParams'

type Props = {
  user?: SignupUserInfo
  promoCode?: string
}

function SignupUserInfoScreenContainer(props: Props & RouteComponentProps) {
  const dispatch = useDispatch()
  const { user, history, promoCode } = props
  const [isLoading, setIsLoading] = useState(true)
  const [pricing, setPricing] = useState()

  useEffect(() => {
    api.orders.pricing({ promoCode }).then((result) => {
      setPricing(result)
      setIsLoading(false)
    })
  }, [])

  const isCapturingSignupParams = useCaptureSignupParams(location.search)

  const { plan } = queryString.parse(location.search)

  async function handleSubmit(data: SignupUserInfo) {
    setIsLoading(true)

    try {
      if (!data.agree) {
        throw new Error(
          'Please verify you understand that Pet Notify is only available in the US and Canada',
        )
      }
      if (!data.firstName) {
        throw new Error('First name required')
      }
      if (!data.lastName || data.lastName === '') {
        throw new Error('Last name required')
      }
      if (!data.email || data.email === '') {
        throw new Error('Email is required')
      }
      if (!isEmail(data.email)) {
        throw new Error('Email must be a valid email address')
      }
      if (data.email !== data.confirmEmail) {
        throw new Error('Your emails do not match')
      }

      setIsLoading(false)
      await dispatch(
        createTempUser({
          ...user,
          ...data,
          promoCode,
          plan: plan === 'yearly' ? 'year' : 'month',
        }),
      )
      history.push('/signup/premium/intellitag')
    } catch (e) {
      setIsLoading(false)
      throw e
    }
  }

  return (
    <SignupUserInfoScreen
      onSubmit={handleSubmit}
      isLoading={isLoading || isCapturingSignupParams}
      initialValues={user}
      hasPromoCode={!!promoCode}
      plan={plan}
      pricing={pricing}
    />
  )
}

type ReduxState = {
  signup: {
    user?: UserInfo
  }
}

export default connect((state: State) => ({
  user: state.signup.user,
  promoCode: state.promoCode && state.promoCode.code,
}))(SignupUserInfoScreenContainer)
