import React from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  Label,
  Input,
  Button,
  ErrorText,
  TextArea,
} from 'components/Forms'
import HeaderOne from 'components/Headers/HeaderOne'
import PhoneInput from 'sharedComponents/Input/Phone'
import style from './style.module.scss'

const VALID_EMAIL = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/

CareProviderForm.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  onRemove: PropTypes.func,
}

function CareProviderForm(props) {
  const { onSubmit, values, onRemove } = props
  const isEditing = !!values

  async function handleSubmit(data) {
    const { type, name, email } = data

    if (!type || type === '') {
      throw new Error(`Type required`)
    }

    if (!name || name === '') {
      throw new Error(`Name required`)
    }

    if (email && email !== '' && !VALID_EMAIL.test(email)) {
      throw new Error(`Email must be a valid email address`)
    }

    await onSubmit(data)
  }

  function handleRemove(event) {
    event.preventDefault()
    onRemove()
  }

  return (
    <div>
      <HeaderOne>{isEditing ? `Edit` : `Add`} care provider</HeaderOne>
      <Form onSubmit={handleSubmit} values={values}>
        {({ values, onChange, error }) => (
          <React.Fragment>
            <Label htmlFor='care-provider-type'>Type</Label>
            <Input
              name='type'
              value={values.type}
              onChange={onChange}
              id='care-provider-type'
            />

            <Label htmlFor='care-provider-name'>Name</Label>
            <Input
              name='name'
              value={values.name}
              onChange={onChange}
              id='care-provider-name'
            />

            <Label htmlFor='care-provider-phone'>Phone</Label>
            <PhoneInput
              placeholder='Optional'
              name='phone'
              value={values.phone}
              onChange={onChange}
              id='care-provider-phone'
            />

            <Label htmlFor='care-provider-email'>Email</Label>
            <Input
              type='email'
              placeholder='Optional'
              name='email'
              value={values.email}
              onChange={onChange}
              id='care-provider-email'
            />

            <Label htmlFor='care-provider-address'>Address</Label>
            <Input
              placeholder='Optional'
              name='address'
              value={values.address}
              onChange={onChange}
              id='care-provider-address'
            />

            <Label htmlFor='care-provider-notes'>Notes</Label>
            <TextArea
              placeholder='Optional'
              name='notes'
              value={values.notes}
              onChange={onChange}
              id='care-provider-notes'
            />
            {error && <ErrorText>{error}</ErrorText>}
            <Button type='submit' small>
              {isEditing ? `Save` : `Add care provider`}
            </Button>
            {isEditing && (
              <Button
                onClick={handleRemove}
                variant='hollowRed'
                small
                className={style.removeButton}
              >
                Remove
              </Button>
            )}
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}

export default CareProviderForm
