import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { update } from 'UserApp/store/user/actions'
import { selectField } from 'UserApp/store/user/selectors'
import MaxTimeAwayForm from 'UserApp/components/Alarm/MaxTimeAwayForm'

class MaxTimeAwayFormContainer extends React.PureComponent {
  static propTypes = {
    update: PropTypes.func,
  }

  state = {
    isProcessing: false,
  }

  handleSubmit = async ({ maxMinutesAway }) => {
    const { update } = this.props
    this.setState({ isProcessing: true })
    await update({ maxMinutesAway: parseInt(maxMinutesAway) })
    this.setState({ isProcessing: false })
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
    onSubmit: this.handleSubmit,
  })

  render() {
    const props = this.generateProps()

    return <MaxTimeAwayForm {...props} />
  }
}

export default connect(
  (state) => ({
    values: { maxMinutesAway: selectField(state, 'maxMinutesAway') || '720' },
  }),
  { update },
)(MaxTimeAwayFormContainer)
