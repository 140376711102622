import React from 'react'
import PropTypes from 'prop-types'

import Row, * as RowElement from '../Row'

import style from './style.module.scss'

VaultItemCareProviders.propTypes = {
  name: PropTypes.string,
}

function VaultItemCareProviders(props) {
  const { careProviders } = props

  return careProviders.map(({ type, name, phone, email, address, notes }) => (
    <div className={style.wrapper}>
      <Row>
        <RowElement.Title>Type</RowElement.Title>
        <RowElement.Value>{type}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Name</RowElement.Title>
        <RowElement.Value>{name}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Phone</RowElement.Title>
        <RowElement.Value>{phone}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Email</RowElement.Title>
        <RowElement.Value>{email}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Address</RowElement.Title>
        <RowElement.Value>{address}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Notes</RowElement.Title>
        <RowElement.Value>{notes}</RowElement.Value>
      </Row>
    </div>
  ))
}

export default VaultItemCareProviders
