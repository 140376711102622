import React from 'react'
import classNames from 'classnames'

import { Link as RouterLink } from 'react-router-dom'
import style from './style.module.scss'

type Props = {
  className?: string
}

function SOSButton({ className }: Props) {
  return (
    <RouterLink
      to='/alarm/sos'
      className={classNames(style.wrapper, className)}
    >
      SOS
    </RouterLink>
  )
}

export default SOSButton
