import React from 'react'
import { setSeconds, setHours, setMinutes } from 'date-fns'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { create } from 'UserApp/store/alarm/actions'
import AlarmForm from 'UserApp/components/Alarm/Form'

function makeDateTime(hour, minute, amPM) {
  let dateTime = new Date()
  const offset = amPM === 'PM' ? 12 : 0

  dateTime = setSeconds(dateTime, 0)
  dateTime = setHours(dateTime, parseInt(hour) + offset)
  dateTime = setMinutes(dateTime, parseInt(minute))

  return dateTime
}

class AlarmFormContainer extends React.PureComponent {
  static propTypes = {
    create: PropTypes.func,
  }

  state = {
    isProcessing: false,
  }

  handleSubmit = async (data) => {
    const { create } = this.props
    this.setState({ isProcessing: true })
    const { startTimeHour, startTimeMinute, startTimeAmPM } = data
    const startTime = makeDateTime(
      startTimeHour,
      startTimeMinute,
      startTimeAmPM,
    )

    try {
      await create({ startTime })
    } catch (error) {
      this.setState({ isProcessing: false })
      throw error
    }
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
    onSubmit: this.handleSubmit,
  })

  render() {
    const props = this.generateProps()

    return <AlarmForm {...props} />
  }
}
export default connect(null, { create })(AlarmFormContainer)
