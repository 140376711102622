import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dropdown,
  ErrorText,
  Form,
  Input,
  Label,
  TextArea,
} from 'components/Forms'
import HeaderOne from 'components/Headers/HeaderOne'
import style from './style.module.scss'
import Text from 'components/Text'

const TYPE_OPTIONS = [``, `GPS Tracker`, `Auto-feeder`, `Camera`, `Other`]

TechDeviceForm.propTypes = {
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
}

function TechDeviceForm(props) {
  const { onSubmit, values, onRemove } = props
  const isEditing = !!values

  async function handleSubmit(data) {
    const { type, brand } = data

    if (!type || type === '') {
      throw new Error(`Type required`)
    }

    if (!brand || brand === '') {
      throw new Error(`Brand required`)
    }

    await onSubmit(data)
  }

  function handleRemove(event) {
    event.preventDefault()
    onRemove()
  }

  return (
    <div>
      <HeaderOne>{isEditing ? `Edit` : `Add`} tech device</HeaderOne>
      <Form onSubmit={handleSubmit} values={values}>
        {({ values, onChange, error }) => (
          <React.Fragment>
            <Label htmlFor='tech-device-type'>Type</Label>
            <Dropdown
              name='type'
              value={values.type}
              onChange={onChange}
              options={TYPE_OPTIONS}
              id='tech-device-type'
            />

            <Label htmlFor='tech-device-brand'>Brand</Label>
            <Input
              name='brand'
              value={values.brand}
              onChange={onChange}
              id='tech-device-brand'
            />

            <Label htmlFor='tech-device-url'>URL</Label>
            <Input
              placeholder='Optional'
              name='url'
              value={values.url}
              onChange={onChange}
              id='tech-device-url'
            />

            <Label htmlFor='tech-device'>Username</Label>
            <Input
              placeholder='Optional'
              name='username'
              value={values.username}
              onChange={onChange}
              id='tech-device-username'
            />

            <Label htmlFor='tech-device'>Password</Label>
            <Input
              placeholder='Optional'
              name='password'
              value={values.password}
              onChange={onChange}
              id='tech-device-password'
            />

            <Label htmlFor='tech-device-notes'>Notes</Label>
            <TextArea
              placeholder='Optional'
              name='notes'
              value={values.notes}
              onChange={onChange}
              id='tech-device-notes'
            />
            <Text>
              We will only share this with your trusted contacts in the event of
              an alarm.
            </Text>
            {error && <ErrorText>{error}</ErrorText>}
            <Button type='submit' small>
              {isEditing ? `Save` : `Add tech device`}
            </Button>
            {isEditing && (
              <Button
                onClick={handleRemove}
                variant='hollowRed'
                small
                className={style.removeButton}
              >
                Remove
              </Button>
            )}
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}

export default TechDeviceForm
