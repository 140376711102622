import React, { useEffect, useState } from 'react'

import api from 'services/pet-notify-api'
import HeaderOne from 'sharedComponents/HeaderOne'
import Text from 'sharedComponents/Text'
import Form from 'sharedComponents/Form'
import Label from 'sharedComponents/Label'
import Input from 'sharedComponents/Input'
import Button from 'sharedComponents/Button'
import Loader from 'sharedComponents/Loader'
import Error from 'sharedComponents/Error'
import Link from 'sharedComponents/Link'
import style from './style.module.scss'
import { useHistory } from 'react-router'
import useUser from 'UserApp/hooks/useUser'
import { Navigate, Redirect, useNavigate } from 'react-router-dom'
import ActivateIntellitagLostPetInstructions from '../LostPetInstructions'
import ActivateIntellitagChoosePets from '../ChoosePets'
import UpgradeToPremiumActivateTag from 'UserApp/components/UpgradeToPremiumActivateTag'

type FormValues = {
  activationCode: string
}

function ActivateIntellitagActivationCode({ onSuccess }) {
  const [orderInfo, setOrderInfo] = useState<any>()
  const history = useHistory()
  const user = useUser()
  const [isProcessing, setIsProcessing] = useState(false)
  const [intellitagInstructions, setIntellitagInstructions] = useState<string>()
  const [hasOrderInfo, setHasOrderInfo] = useState<any>()
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [paymentSuccess, setPaymentRedirect] = useState<boolean>(
    user?.user?.hasSubscription || false,
  )

  console.log(`payment success ::`, paymentSuccess)
  console.log(`User info `, user?.user.hasSubscription)

  function handleSetIntellitagInstructions(intellitagInstuctions) {
    setIntellitagInstructions(intellitagInstuctions)
    if (orderInfo) {
      setHasOrderInfo(orderInfo)
    }
  }

  function handleSuccessChoosePets() {
    history.push(`/pets/activate-intellitag/activation-success`)
  }

  async function handleSubmit({ activationCode }: FormValues) {
    setIsProcessing(true)
    try {
      const order = await api.orders.fetchByActivationCode(activationCode)
      if (user.user.isVerified === true) {
        setIsVerified(true)
        setOrderInfo(order)
      }
    } catch (e) {
      setIsProcessing(false)
      throw e
    }
  }

  console.log('paymentSuccess type', typeof paymentSuccess)

  return (
    <>
      {!orderInfo && (
        <>
          <HeaderOne>Activate Intellitag</HeaderOne>

          <Text className={style.text}>
            Activating your Intellitag will turn on Intellitag pet monitoring
            and lost pet messaging.
          </Text>

          <Form onSubmit={handleSubmit}>
            {({ onChange, values, error }) =>
              isProcessing ? (
                <Loader />
              ) : (
                <>
                  <Label htmlFor='activationCode'>Enter activation code</Label>
                  <Input
                    id='activationCode'
                    value={values.activationCode}
                    onChange={onChange}
                    name='activationCode'
                    className={style.input}
                  />

                  <Text variant='note' className={style.note}>
                    Your activation code is included with your Intellitag
                    shipment. If lost, contact{' '}
                    <Link href='mailto:support@petnotify.com'>
                      mailto:support@petnotify.com
                    </Link>
                  </Text>

                  {error && <Error>{error}</Error>}
                  <Button type='submit' className={style.button}>
                    Activate Intellitag
                  </Button>
                </>
              )
            }
          </Form>
        </>
      )}

      {orderInfo &&
        (hasOrderInfo ? (
          <ActivateIntellitagChoosePets
            order={hasOrderInfo}
            intellitagInstructions={intellitagInstructions}
            onSuccess={handleSuccessChoosePets}
          />
        ) : user?.user?.hasSubscription ? (
          <ActivateIntellitagLostPetInstructions
            order={orderInfo}
            onSaveIntellitagInsructions={handleSetIntellitagInstructions}
          />
        ) : user?.user?.hasSubscription === false || paymentSuccess === false ? (
          <UpgradeToPremiumActivateTag
            order={orderInfo}
            setPaymentRedirect={setPaymentRedirect}
          />
        ) : (
          ''
        ))}
    </>
  )
}

export default ActivateIntellitagActivationCode
