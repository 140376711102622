import React, { FormEvent, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import HeaderOne from 'sharedComponents/HeaderOne'
import Text from 'sharedComponents/Text'
import Label from 'sharedComponents/Label'
import Button from 'sharedComponents/Button'
import Dropdown from 'sharedComponents/Dropdown'
import Loader from 'sharedComponents/Loader'
import Error from 'sharedComponents/Error'
import { selectPetsWithoutIntellitags } from 'UserApp/store/pets/selectors'
import { activateIntellitags } from 'UserApp/store/pets/actions'
import style from './style.module.scss'

function ActivateIntellitagChoosePets({
  order,
  onSuccess,
  intellitagInstructions,
}) {
  const dispatch = useDispatch()
  const [petIds, setPetIds] = useState([])
  const [error, setError] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const pets = useSelector(selectPetsWithoutIntellitags)

  console.log(`Choose pets components ::`, order)

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setError('')

    if (order.intellitagOrderItems.length !== petIds.length) {
      setError('Please choose all the pets')
      return
    }

    setIsProcessing(true)

    try {
      await dispatch(
        activateIntellitags(order.activationCode, {
          intellitagInstructions,
          petIds,
        }),
      )
      onSuccess()
    } catch (e) {
      setIsProcessing(false)
      setError(e)
    }
  }

  function handleChange({ index, value }) {
    setPetIds((currentPetIds) => {
      const newPetIds = [...currentPetIds]
      newPetIds[index] = value
      return newPetIds
    })
  }

  if (isProcessing) {
    return <Loader />
  }
  console.log('error', error)
  return (
    <>
      <HeaderOne>Choose your pet</HeaderOne>

      <Text className={style.text}>
        Assign your intellitag to the correct pets
      </Text>

      <form onSubmit={handleSubmit} className={style.form}>
        {order?.intellitagOrderItems?.map((intellitagOrderItem, index) => (
          <div className={style.intellitag} key={intellitagOrderItem.id}>
            <Label htmlFor='activationCode'>
              Intellitag #{index + 1} : {intellitagOrderItem.name}
            </Label>
            <Dropdown
              name='pet'
              options={pets.map((pet, petIndex) => {
                const disabled = petIds.find(
                  (petId, petIdindex) =>
                    petId === pet.id && index !== petIdindex,
                )

                return {
                  value: pet.id,
                  label: pet.name,
                  disabled,
                }
              })}
              id='intellitag-pet'
              value={petIds[index]}
              onChange={(props) => handleChange({ ...props, index })}
              placeholder='Choose your pet'
            />
          </div>
        ))}
        {error && (
          <Error>
            {'Oops, something went wrong and we are looking into it'}
          </Error>
        )}
        <Button type='submit'>Activate Intellitags</Button>
      </form>
    </>
  )
}

export default ActivateIntellitagChoosePets
