import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Label, Input, Button, ErrorText } from 'components/Forms'
import HeaderOne from 'components/Headers/HeaderOne'
import Loader from 'components/Loader'
import Text from 'components/Text'
import Link from 'components/Link'
import style from './style.module.scss'

DocumentForm.propTypes = {
  error: PropTypes.string,
  file: PropTypes.object,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  onChangeName: PropTypes.func,
  onRemove: PropTypes.func,
  onSelectFile: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
}

function DocumentForm(props) {
  const {
    error,
    file,
    isLoading,
    name,
    onChangeName,
    onRemove,
    onSelectFile,
    onSubmit,
    values,
  } = props

  if (isLoading) {
    return <Loader />
  }

  const inputRef = useRef(null)

  function handleUploadButtonClick(event) {
    event.stopPropagation()
    inputRef.current.click()
  }

  const isEditing = !!values

  const fileExt = file && file.name.split('.').pop().toLowerCase()

  return (
    <form onSubmit={onSubmit} className={style.wrapper}>
      <HeaderOne>{isEditing ? `Edit` : `Add`} pet document</HeaderOne>
      <input
        ref={inputRef}
        type='file'
        name='file'
        onChange={onSelectFile}
        className={style.file}
      />
      {fileExt ? (
        <React.Fragment>
          <Label htmlFor='pet-name'>Document name</Label>
          <div className={style.input}>
            <Input
              name='name'
              value={name}
              onChange={onChangeName}
              id='pet-name'
              className={style.nameField}
            />
            <div className={style.fileExt}>{fileExt}</div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Button
            variant='hollow'
            onClick={handleUploadButtonClick}
            small
            className={style.uploadButton}
          >
            Choose document
          </Button>
          <Text className={style.note} variant='note2'>
            JPG, PDF, PNG DOC or DOCX supported. Max size 10mb.
          </Text>
        </React.Fragment>
      )}
      {error && <ErrorText>{error}</ErrorText>}

      <div className={style.options}>
        <Button type='submit' small disabled={!fileExt || name === ''}>
          Save
        </Button>

        {isEditing && values.url && (
          <Button
            variant='hollow'
            href={values.url}
            target='_blank'
            className={style.downloadLink}
          >
            View/Download
          </Button>
        )}
        {isEditing && (
          <Link onClick={onRemove} small className={style.removeButton}>
            Remove
          </Link>
        )}
      </div>
    </form>
  )
}

export default DocumentForm
