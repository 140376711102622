import React from 'react'
import { Switch, Route } from 'react-router-dom'
import classNames from 'classnames'
import queryString from 'query-string'
import PropTypes from 'prop-types'

import { Button } from 'components/Forms'
import { UserAddressType } from 'types'
import HeaderThree from 'components/Headers/HeaderThree'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Layout from 'UserApp/components/Layout'
import Text from 'sharedComponents/Text'
import HeaderOne from 'sharedComponents/HeaderOne'

import IntellitagCTA from 'UserApp/components/IntellitagCTA'
import RowElement, * as Row from 'UserApp/components/Row'
import PetForm from 'UserApp/containers/Pets/Form'
import PetsList from 'UserApp/containers/Pets/List'
import AddressForm from 'UserApp/containers/Address/Form'
import ActivateIntellitag from 'UserApp/components/ActivateIntellitag'

import style from './style.module.scss'

PetVaultScreen.propTypes = {
  address: PropTypes.shape(UserAddressType),
  isAddingAddress: PropTypes.bool,
  isAddingPet: PropTypes.bool,
  isEditingAddress: PropTypes.bool,
  isEditingPet: PropTypes.bool,
  onClose: PropTypes.func,
  petId: PropTypes.string,
}

function PetVaultScreen(props) {
  const {
    address,
    isAddingAddress,
    isEditingAddress,
    onClose,
    petId,
    hasPetsWithouIntellitags,
    dismissIntellitagCta,
    onDismissIntellitagCta,
    user,
    location,
  } = props
  const { hasSubscription, status } = user

  const { activated } = queryString.parse(location.search)
  
  return (
    <Layout title='Pet Vault'>
      <div className={style.wrapper}>
        <div className={style.pets}>
          <HeaderThree className={style.header}>Your pets</HeaderThree>
          <PetsList />

          <Button to='/pets/new' small>
            Add Pet
          </Button>
        </div>
        {(hasPetsWithouIntellitags || !hasSubscription) &&
          !dismissIntellitagCta && (
            <div className={style.intellitagCta}>
              <IntellitagCTA
                onClose={onDismissIntellitagCta}
                hasSubscription={hasSubscription}
                status={status}
              />
            </div>
          )}
        <div className={style.home}>
          <HeaderThree
            className={classNames(style.header, style.addressHeader, {
              [style.hasAddress]: !!address,
            })}
          >
            Where your pets live
          </HeaderThree>
          {address ? (
            <RowElement className={style.row}>
              <Row.Value className={style.addressValue}>
                {address.address}
              </Row.Value>
              <Row.Button>
                <Link to='/address/edit'>Edit</Link>
              </Row.Button>
            </RowElement>
          ) : (
            <Button to='/address/new' className={style.button} small>
              Add address
            </Button>
          )}
        </div>
      </div>

      {activated && (
        <Modal onClose={onClose}>
          {() => (
            <div className={style.activated}>
              <HeaderOne>Welcome to PetNotify</HeaderOne>
              <Text className={style.activatedText}>
                To finalize the Intellitag activation process, upload and click
                on your pet's photo, to complete their Intellitag Lost Pet
                Instructions. There is no limit to the amount of information you
                can include, ensuring your pet's quick and safe return home.
              </Text>
              <Button onClick={onClose}>Continue to Pet Vault</Button>
            </div>
          )}
        </Modal>
      )}

      {(isEditingAddress || isAddingAddress) && (
        <Modal onClose={onClose}>
          {({ onChangedView }) => (
            <AddressForm
              onClose={onClose}
              isEditing={isEditingAddress}
              onChangedView={onChangedView}
            />
          )}
        </Modal>
      )}

      <Switch>
        <Route
          path='/pets/activate-intellitag'
          children={(props) => (
            <Modal onClose={onClose}>
              {() => <ActivateIntellitag {...props} />}
            </Modal>
          )}
        />
        <Route
          path='/pets/:id'
          children={() => (
            <Modal onClose={onClose} enableClickOffToClose={false}>
              {({ onChangedView }) => (
                <PetForm
                  onClose={onClose}
                  id={petId}
                  onChangedView={onChangedView}
                />
              )}
            </Modal>
          )}
        />
        <Route
          path='/pets/new'
          children={() => (
            <Modal onClose={onClose} enableClickOffToClose={false}>
              {({ onChangedView }) => (
                <PetForm onClose={onClose} onChangedView={onChangedView} />
              )}
            </Modal>
          )}
        />
      </Switch>
    </Layout>
  )
}

export default PetVaultScreen
