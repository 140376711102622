import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import HeaderOne from 'components/Headers/HeaderOne'
import Text from 'components/Text'
import { Button } from 'components/Forms'
import style from './style.module.scss'

TurnOffConfirmation.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

function TurnOffConfirmation(props) {
  const { onCancel, onConfirm } = props

  return (
    <div className={classNames(style.wrapper, style.expired)}>
      <HeaderOne className={style.header}>Turn alarm off</HeaderOne>

      <Text className={style.text}>
        Are you sure you want to turn off the alarm? We'll notify all your
        previously notified contacts that you no longer need assistance, and
        will revoke their access to your Pet Vault.
      </Text>

      <Button onClick={onCancel} variant='hollow' className={style.button}>
        Cancel
      </Button>
      <Button onClick={onConfirm} variant='red' className={style.button}>
        Turn Alarm Off
      </Button>
    </div>
  )
}

export default TurnOffConfirmation
