import React, { useState } from 'react'
import numeral from 'numeral'

import Layout from 'sharedComponents/Layout/Split'
import Loader from 'sharedComponents/Loader'
import Form from 'sharedComponents/Form'
import Button from 'sharedComponents/Button'
import Label from 'sharedComponents/Label'
import Checkbox from 'sharedComponents/Checkbox'
import Error from 'sharedComponents/Error'
import Input from 'sharedComponents/Input'
import HeaderOne from 'sharedComponents/HeaderOne'
import Text from 'sharedComponents/Text'
import Steps from 'UserApp/components/Steps'
import PromoCode from 'UserApp/components/InlinePromoCode'
import MobileHeaderImage from '../../MobileHeaderImage'
import Link from 'sharedComponents/Link'
import style from './style.module.scss'
import PremiumFeaturesModal from 'UserApp/components/PremiumFeaturesModal'
import mixPanel, { EVENTS } from 'services/mix-panel'

type Props = {
  pricing?: Pricing
  onSubmit: (userInfo: SignupUserInfo) => Promise<void>
  isLoading: boolean
  initialValues?: SignupUserInfo
  hasPromoCode: boolean
  plan: string
}

function SignupUserInfoScreen(props: Props) {
  const { onSubmit, isLoading, initialValues, plan, pricing } = props

  const [isShowingModal, setIsShowingModal] = useState(false)

  return (
    <>
      <Layout>
        <MobileHeaderImage step={1} />
        <Steps currentStep={1} totalSteps={4} />
        <HeaderOne className={style.title}>Welcome to Pet Notify!</HeaderOne>
        <Text className={style.text}>
          Create your premium account to customize your Intellitag and start
          protecting your pets.
        </Text>
        <Link onClick={() => setIsShowingModal(true)} className={style.link}>
          What else does Premium include?
        </Link>
        <Form<SignupUserInfo>
          onSubmit={onSubmit}
          initialValues={initialValues}
          className={style.form}
        >
          {({ onChange, values, error }) =>
            isLoading ? (
              <Loader />
            ) : (
              <>
                <Label htmlFor='signup-first-name'>First Name</Label>
                <Input
                  type='text'
                  name='firstName'
                  id='signup-first-name'
                  value={values.firstName}
                  onChange={onChange}
                />
                <Label htmlFor='signup-last-name'>Last Name</Label>
                <Input
                  type='text'
                  name='lastName'
                  id='signup-last-name'
                  value={values.lastName}
                  onChange={onChange}
                />

                <Label htmlFor='signup-email'>Email</Label>
                <Input
                  type='email'
                  name='email'
                  id='signup-email'
                  value={values.email}
                  onChange={onChange}
                />

                <Label htmlFor='signup-confirm-email'>Confirm email</Label>
                <Input
                  type='email'
                  name='confirmEmail'
                  id='signup-confirm-email'
                  value={values.confirmEmail}
                  onChange={onChange}
                />
                <Checkbox
                  name='agree'
                  value={values.agree}
                  onChange={onChange}
                  label='I understand Pet Notify is only available in the US and Canada'
                  id='signup-agree'
                />

                {error && <Error>{error}</Error>}

                <Button
                  type='submit'
                  onClick={() =>
                    mixPanel.track(EVENTS.PREMIUM_CREATE_ACCOUNT_CLICK)
                  }
                >
                  Create account
                </Button>
              </>
            )
          }
        </Form>

        <PromoCode isAnnualPlanSelected={plan === 'yearly'} />

        {!isLoading && (
          <ul className={style.notes}>
            <li>No commitments, cancel anytime</li>
            <li>
              Only one subscription is required per household and covers
              unlimited pets
            </li>
          </ul>
        )}

        {isShowingModal && (
          <PremiumFeaturesModal onClose={() => setIsShowingModal(false)} />
        )}
      </Layout>
      <img
        src='https://pubads.g.doubleclick.net/activity;xsp=4691457;ord=[session id]?'
        width='1'
        height='1'
        border='0'
      />
    </>
  )
}

export default SignupUserInfoScreen
