import React from 'react'
import PropTypes from 'prop-types'

import Loader from 'components/Loader'
import { Form, Dropdown, Button, ErrorText } from 'components/Forms'
import style from './style.module.scss'

const OPTIONS = [
  { value: '30', label: '30 minutes' },
  { value: '60', label: '1 hour' },
  { value: '120', label: '2 hours' },
  { value: '240', label: '4 hours' },
  { value: '360', label: '6 hours' },
  { value: '480', label: '8 hours' },
  { value: '600', label: '10 hours' },
  { value: '720', label: '12 hours' },
  { value: '1440', label: '24 hours' },
  { value: '2880', label: '48 hours' },
  { value: '4320', label: '72 hours' },
]

MaxTimeAwayForm.propTypes = {
  isProcessing: PropTypes.bool,
  values: PropTypes.object,
  onSubmit: PropTypes.func,
}

function MaxTimeAwayForm(props) {
  const { isProcessing, values, onSubmit } = props

  return (
    <Form values={values} onSubmit={onSubmit} className={style.wrapper}>
      {({ onChange, values, error }) =>
        isProcessing ? (
          <Loader />
        ) : (
          <>
            <div>
              <Dropdown
                name='maxMinutesAway'
                value={values.maxMinutesAway}
                onChange={onChange}
                options={OPTIONS}
                className={style.dropdown}
              />
            </div>
            {error && <ErrorText>{error}</ErrorText>}

            <Button type='submit' className={style.button}>
              Save
            </Button>
          </>
        )
      }
    </Form>
  )
}

export default MaxTimeAwayForm
